.uploadIpList {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 40px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--1, #6a113c);
  background: var(--7, #fff5fa);
  color: var(--1, #6a113c);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  margin-bottom: 20px;
}

.divIpDesign {
    display: flex;
padding: 20px;
flex-direction: column;

gap: 32px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--6, #FADCEA);
background: var(--7, #FFF5FA);
}
.ipGroup {
}

thead{
  .ant-table-cell-fix-right-first {
    filter: drop-shadow(-1px 0px 5px #9f9e9e);
  }
}
