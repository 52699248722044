.tpmSummaryDiv {
  display: flex;
  width: 100%;
  padding: 60px 40px 80px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
}

.tpmSummaryHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.custom-radio-group {
  .custom-radio-button {
    border: 1px solid #d9d9d9;
    color: #000000d9;
    border-radius: 0;
    &:not(:first-child) {
      margin-left: -1px;
    }
  }
  .custom-radio-button.ant-radio-button-wrapper-checked {
    border-color: var(--3, #d42177);
    color: var(--3, #d42177);
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
  }
}

.tpmSummaryProject {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.tpmSummaryProjectName {
  margin-top: 20px;
  display: flex;
  padding: 17px 35px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
  background: var(--2, #aa1a5f);
  color: var(--15, #fff);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 154.545% */
}

.tpmSummaryProjectInfo {
  display: flex;
  height: 100%;
  padding: 32px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 0px 0px 8px 8px;
  border-right: 1.5px solid var(--6, #fadcea);
  border-bottom: 1.5px solid var(--6, #fadcea);
  border-left: 1.5px solid var(--6, #fadcea);
  background: var(--7, #fff5fa);
  flex-wrap: wrap;
}

.tpmSummaryCollectionGoal {
  display: flex;
  width: 50%;
  padding: 16px 20px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--6, #fadcea);
  background: #fff;
}

.collectionGoalHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--Blue-purple-Dark-purple, #252938);

  /* 18pt Body */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.collectionGoalProgress {
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.progressLegend {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.legendItem {
  font-size: 12px;
}

.completed {
  color: #45a566; /* Green color for completed */
}

.notDone {
  color: #6b7280; /* Grey color for not done */
}

/* Custom styles for Progress bar */
.ant-progress-bg {
  background-color: #bfbfbf; /* Grey color as default */
}

.ant-progress-inner {
  background-color: #45a566; /* Green color for completed */
}

.progressInfo {
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  flex-wrap: wrap;
}

.completionPercentage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  color: #45a566;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  margin-top: 20px;
}

.itemsCompleted {
  margin-left: 20px;
  color: #45a566;
  text-align: right;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 170% */
}

.activitySnapshot {
  display: flex;
  padding: 48px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 8px;
  border: 1.5px solid var(--6, #fadcea);
  background: var(--Greys-White, #fff);
}

.activitySnapshotHeader {
  color: var(--2, #aa1a5f);

  /* H3 - 22pt */
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 118.182% */
}

.activitySnapshotResultCard {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 20px;
  align-self: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}

.activitySnapshotCard {
  flex: 0 1 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  display: flex;
  min-height: 160px;
  padding: 32px 20px;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  row-gap: 28px;
  //   flex-wrap: wrap;
  border-radius: 10px;
  border: 1px solid var(--6, #fadcea);
  background: var(--7, #fff5fa);
}

@media (max-width: 1100px) {
  .activitySnapshotCard {
    flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .activitySnapshotCard {
    flex: 0 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}

@media (max-width: 500px) {
  .circleContainer {
    .ant-progress-inner {
      width: 150px !important;
      height: 150px !important;
    }
  }
}

.activitySnapshotNumber {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 8px;
}

.activitySnapshotNumber1 {
  color: var(--2, #aa1a5f);
  font-family: Poppins;
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 10px; /* 43.478% */
}
.activitySnapshotText {
  color: var(--Greys-Dark-gray, #707073);

  /* 18pt Body */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  word-wrap: break-word;
}

.activitySnapshotImg {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 40px;
  background: var(--6, #fadcea);
}

.activitySnapshotCard1 {
  flex: 0 1 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  display: flex;
  min-height: 160px;
  padding: 32px 20px;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  row-gap: 28px;
  //   flex-wrap: wrap;
  border-radius: 10px;
  border: 1px solid var(--6, #fadcea);
  background: var(--7, #fff);
}

@media (max-width: 1100px) {
  .activitySnapshotCard1 {
    flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .activitySnapshotCard1 {
    flex: 0 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}

.activitySnapshotImg1 {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 40px;
  background: var(--7, #fff5fa);
}

.dashboardLocationFilters {
  display: flex;
  padding: 20px 16px;
  align-items: flex-end;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1.5px solid var(--13, #e6e6e6);
  background: rgba(246, 246, 246, 0.4);
}

@media (max-width: 767px) {
  .dashboardLocationFilters {
    display: block;
  }
}

.donorListHeaderText {
  color: var(--2, #aa1a5f);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
}

.donorListHeader2Text {
  color: var(--2, #aa1a5f);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.donorListItem {
  display: flex;
  padding: 12px 0px;
  margin: 10px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--13, #e6e6e6);
  color: var(--9, #404040);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.donorsDiv {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  flex-wrap: wrap;
}

.donorsLeft {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  max-width: 60%;
  height: 100%;
  flex-wrap: wrap;
}
.donorsRight {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  flex: 1 0 0;
  margin: 10px;
  border-radius: 8px;
  border: 1px solid var(--13, #e6e6e6);
  background: rgba(246, 246, 246, 0.16);
}
.donorsCard {
  display: flex;
  width: calc(50% - 20px); /* Adjust width as needed */
  margin: 10px;
  padding: 32px 20px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--13, #e6e6e6);
  background: var(--15, #fff);
}

@media (max-width: 1100px) {
  .donorsCard {
    flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .donorsCard {
    flex: 0 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}

.donorsCardsContainer {
  display: flex;
  flex-wrap: wrap;
}

.donorContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.taskLeft {
  display: flex;
  padding: 24px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
  border: 1.5px solid var(--13, #e6e6e6);
  background: #fff;
  min-width: 60%;
}

.taskRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  max-width: 40%;
}
.activityTaskCard {
  display: flex;

  padding: 32px 20px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 28px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 10px;
  border: 1px solid var(--6, #fadcea);
  background: var(--7, #fff5fa);
}

.taskCircleProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 73px;
  flex-wrap: wrap;
}

.circleContainer {
  flex: 1;
  margin-right: 20px; /* Adjust this value for the spacing between circle and text */
}

.statusText {
  display: flex;
  width: 172px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
}

.acceptedText {
  color: var(--10, #737373);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
}
.completedText {
  color: #45a566;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
}

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  margin: 0;
  display: none;
}