//tabs design 
.flagsPage {
    
    .ant-tabs-tab {
        color: #777777;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 160% */
        letter-spacing: -0.15px;
    }

    .ant-tabs-tab:hover {
        color: var(--2, #AA1A5F) !important;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--2, #AA1A5F) !important;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 160% */
        letter-spacing: -0.15px;
    }

    .ant-tabs-ink-bar-animated {
        color: var(--2, #AA1A5F) !important;

        border-bottom: 2px solid var(--2, #AA1A5F) !important;
    }

    .ant-tabs-tab-active {
        border-bottom: 2px solid var(--2, #AA1A5F) !important;
    }


    //buttons



    .addButtonTPM {
        background: var(--1, #6A113C) !important;
    }

    .exportButtonTPM {
        background: var(--2, #aa1a5f) !important;
    }

    .actionDiv{
        justify-content: space-evenly;
    }

    .tableViewcontainer {
        padding: 60px 40px;
    }


    .tableHeader {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 80px;
        align-self: stretch;
        margin-bottom: 40px;
    }

    .tableViewHeaderText {
        color: var(--2, #AA1A5F);
        /* Basic/Title */
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        /* 145.455% */

    }

    .tableViewHeaderButtons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        gap: 18px;
        flex: 1 0 0;

    }
    .boldedColumn{
        font-weight: 800;
    }
    .description{
        text-wrap: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
    .customTabs .ant-tabs-tab {
        color: var(--Neutrals-Gray-400, #777);
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 160% */
        letter-spacing: -0.15px;
    }

    .customTabs .ant-tabs-tab-active {
        color: var(--2, #AA1A5F);
    }

    .filtersWrapper {
        margin: 40px 0px;
    }

    // Status style

    .archived {
        width: fit-content;
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: var(--Neutrals-Gray-0, #E8E8E8);

        span {
            color: var(--Neutrals-Gray, #555);

            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }

    .resolved {
        width: fit-content;
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: #DEF5F5;

        span {
            color: #045B46;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }

    .onHold {
        width: fit-content;
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: linear-gradient(0deg, #FBF0E5 0%, #FBF0E5 100%), #D46600;

        span {
            color: #D46600;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }

    .inProgress {
        width: fit-content;
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: var(--Primary-Blueberry-300, #DDE9FF);
        span {
            color: var(--Primary-Blueberry-700, #325899);
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }

    .assigned {
        width: fit-content;
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: var(--system-yellow-warning-400, #FFF9F0);
        span {
            color: var(--system-yellow-warning, #FC9403);
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }

    thead {

        .styledTitle{
            text-wrap: nowrap;
        }

        .ant-table-cell-fix-right-first {
            filter: drop-shadow(-1px 0px 5px #9f9e9e);
        }
    }


    .filtersContainer {
        width: 100%;
        gap: 24px;
    }


}