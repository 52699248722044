.questionType {
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    margin-bottom: 24px;
    border-radius: 8px;
    border: 1px solid var(--6, #FADCEA);
    margin: 32px 0;

    .questionTypeHeader {
        display: flex;
        padding: 20px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-radius: 8px 8px 0px 0px;
        border-bottom: 1px solid var(--6, #FADCEA);
        background: var(--7, #FFF5FA);

        div {
            width: 100%;

            .ant-radio-wrapper {
                padding: 12px;
                gap: 10px;
                flex: 1 0 0;
                border-radius: 8px;

                .ant-radio-inner {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;

                }
            }

            .ant-radio-wrapper.yes-radio {
                border: 1px solid var(--system-red-error, #E31A32);

                .ant-radio-inner {
                    border: 1px solid var(--system-red-error, #E31A32);
                }

                .ant-radio-inner::after {
                    background-color: #E31A32;
                }
            }

            .ant-radio-wrapper-checked.yes-radio {
                background: var(--system-red-error-400, #FDEFF0);
            }

            .ant-radio-wrapper.no-radio {
                border: 1px solid var(--system-green-sucess, #0E9349);

                .ant-radio-inner {
                    border: 1px solid var(--system-red-error, #0E9349);
                }

                .ant-radio-inner::after {
                    background-color: #0E9349;
                }
            }

            .ant-radio-wrapper-checked.no-radio {
                background: var(--system-green-sucess-400, #EEF7F2);
            }
        }

    }

    .questionTypeBody2.hidden,
    .questionTypeBody1.hidden{
        display: none !important;
    }

    .questionTypeBody1 {
        display: flex;
        padding: 0px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;

        .ant-form-item {
            width: 100%;
        }
    }
    .questionTypeBody2{
        .ant-form-item-control-input {
            display: flex;
            height: 81px;
            padding: 22.5px 98px 22.5px 130px;
            justify-content: flex-end;
            align-items: center;
            align-self: stretch;
        }
    }
    .ant-radio-wrapper {
        padding: 12px;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;

        .ant-radio-inner {
            width: 20px;
            height: 20px;
            background-color: transparent;

        }
    }

    .ant-radio-wrapper.yes-radio {
        border: 1px solid var(--system-red-error, #E31A32);

        .ant-radio-inner {
            border: 1px solid var(--system-red-error, #E31A32);
        }

        .ant-radio-inner::after {
            background-color: #E31A32;
            left: 3px;
            top: 3px;
            width: 28px;
            height: 28px;
        }
    }

    .ant-radio-wrapper-checked.yes-radio {
        background: var(--system-red-error-400, #FDEFF0);
    }

    .ant-radio-wrapper.no-radio {
        border: 1px solid var(--system-green-sucess, #0E9349);

        .ant-radio-inner {
            border: 1px solid var(--system-red-error, #0E9349);
        }

        .ant-radio-inner::after {
            background-color: #0E9349;
            left: 3px;
            top: 3px;
            width: 28px;
            height: 28px;
        }
    }

    .ant-radio-wrapper-checked.no-radio {
        background: var(--system-green-sucess-400, #EEF7F2);
    }

    .questionInfo {
        align-self: stretch;
        color: var(--Neutrals-Black, #3A3737);

        /* Inputs/Label */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 137.5% */

        span {

            display: block;

            color: var(--Neutrals-Gray-300, #979797);

            /* Inputs/Text */
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
        }

    }





    .ant-slider-step,
    .ant-slider-mark {
        color: var(--Neutral-3, #F5F5F5) !important;
        border-radius: 100px;
        background: var(--Neutral-3, #F5F5F5) !important;

        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 144%;
        /* 20.16px */
        letter-spacing: 0.14px;

        :nth-child(1) {
            color: #E31A32;
        }

        :nth-child(2) {
            color: #E31A32;
        }

        :nth-child(3) {
            color: #E8AF11;
        }

        :nth-child(4) {
            color: #3D9B4C;
        }

        :nth-child(5) {
            color: #3D9B4C;
        }
    }


    .ant-slider-dot {
        width: 8px;
        height: 8px;
        flex-shrink: 0;
        border-radius: 17px;
        border: 2px solid var(--Neutrals-Gray-300, #979797) !important;
        background: var(--Neutral-1, #FFF);
    }

}