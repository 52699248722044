.mainDivProjects {
  display: flex;
  width: 100%;
  padding: 40px 40px 80px 40px;
  flex-direction: column;
  gap: 40px;
  border-radius: 16px;
  background: var(--Greys-White, #fff);
}

.backText {
  color: var(--Neutrals-Gray-400, #777);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin-left: 10px;
  cursor: pointer;
}
