.ant-message-notice-wrapper,
.ant-message-notice,
.ant-message-notice-content {
    background-color: transparent !important;
    box-shadow: none !important;

    .anticon-check-circle,
    .anticon-spin {
        display: none;
    }
}

.messageContainer {
    position: relative !important;
    display: flex;
    min-width: 80vw !important;
    max-width: 100vw !important;
    align-items: flex-start;
    box-shadow: 0px 4px 12px 0px rgba(59, 75, 104, 0.25);
    border-radius: 8px 8px 8px 8px;

    &.success {
        .icon {
            background: var(--Surface-Green-200, #F2FEFE);
            img {
                filter: brightness(0) saturate(100%) invert(41%) sepia(78%) saturate(1274%) hue-rotate(88deg) brightness(92%) contrast(91%);
            }
        }
        .text h1 {
            color: var(--system-green-success, #0E9349);
        }
    }

    &.danger {
        .icon {
            background: #FDEDEF;
            img {
                filter: brightness(0) saturate(100%) invert(23%) sepia(79%) saturate(2401%) hue-rotate(340deg) brightness(92%) contrast(91%);
            }
        }
        .text h1 {
            color: var(--system-red-error, #E31A32);
        }
    }

    .icon {
        display: flex;
        padding: 20px 24px;
        align-items: center;
        gap: 2px;
        align-self: stretch;
        border-radius: 8px 0px 0px 8px;

        img {
            display: flex;
            align-items: flex-start;
            gap: 10px;
        }
    }

    .content {
        display: flex;
        padding: 5px 10px 5px 24px;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 0px 8px 8px 0px;
        background: var(--Neutrals-White, #FFF);

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex: 1 0 0;
            text-align: start;

            h1 {
                align-self: stretch;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 144%;
            }

            p {
                align-self: stretch;
                color: var(--Neutrals-Black, #3A3737);
                font-family: Lato;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 144%;
            }
        }
    }

    button {
        border: none !important;

        img {
            display: flex;
            width: 24px;
            height: 24px;
            padding: 3px;
            justify-content: center;
            align-items: center;
            color: #555555;
        }
    }

    .loading-bar {
        position: absolute;
        bottom: 0;
        left: 2px;
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, var(--2, #AA1A5F), var(--1, #6A113C));
        animation: loadingAnimation 2.5s linear forwards;
    }

    @keyframes loadingAnimation {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }
}
