.modalSubTitle {
  h3 {
    color: var(--2, #aa1a5f);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
    /* 25.92px */
  }
}


.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .last-update {
    font-size: 12px;
    color: #888;
    /* Adjust the color as needed */
  }
}

.ant-modal-title {
  display: block;
  width: 100%;
  text-align: center;
  color: var(--2, #aa1a5f) !important;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  padding: 20px 0;
}

.modal-content {
  .ant-modal-header {
    background-color: red;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .column {
    flex: 1;
    min-width: 50%;
    /* Ensure columns are at least 50% of the container width */
    box-sizing: border-box;
    padding: 0 15px;

    /* Add padding for spacing between columns */
    p {
      color: black;
    }

    strong {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: var(--2, #aa1a5f) !important;
      font-family: Poppins !important;
      font-size: 15px !important;
      font-style: normal !important;
      font-weight: 600 !important;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;

  .column {
    flex: 1;
    min-width: 50%;
    box-sizing: border-box;
    padding: 0 15px;
  }
}

.contact-info {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px 32px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 8px;
  border: 1px solid var(--6, #fadcea);
  background: var(--7, #fff5fa);
}

.ant-upload-wrapper {
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px dashed var(--6, #fadcea);
  background: var(--7, #fff5fa);

  .ant-upload {
    :hover {
      background-color: none !important;
      color: var(--1, #6a113c) !important;
    }

    .uploadButton {
      color: var(--2, #aa1a5f);
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 150% */
      border-style: none;
      background: none;
      box-shadow: none;

      :hover {
        background-color: none !important;
        color: var(--1, #6a113c) !important;
      }
    }

    .bottomText {
      display: flex;
      flex-direction: column;
      text-align: center;

      .span1 {
        color: var(--Neutrals-Gray-400, #777);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 171.429% */
      }

      .span2 {
        color: var(--Neutrals-Gray-400, #777);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .span3 {
        color: var(--Neutrals-Gray-400, #777);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .span4 {
        color: var(--Neutrals-Gray-400, #777);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }
    }
  }

  //   .ant-upload-list {
  //     display: flex;
  //     padding: 12px 16px;
  //     flex-direction: column;
  //     align-items: flex-start;
  //     gap: 4px;
  //     align-self: stretch;
  //     border-radius: 8px;
  // border: 1px solid var(--Primary-Main-blue-200, #DFE6FF);
  // background: var(--14, #F6F6F6);
  //   }
}

.postponeElement {
  padding: 20px 0;

  span {
    color: var(--9, #404040);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    /* 23.04px */
  }
}

.resourcesElement {
  .resourcesLabel {
    p {
      color: var(--11, #999);
      font-family: Poppins;
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      line-height: 22px;
      /* 137.5% */
    }
  }

  .resourcesLabel {
    label {
      color: var(--2, #aa1a5f);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 144%;
      /* 25.92px */
    }
  }

  .resourcesUploadElement {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .uploadLabels {

      label {

        :hover {
          border: 2px solid var(--2, #AA1A5F);
        }

        color: var(--Neutrals-Black, #3a3737);

        /* Inputs/Label */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 137.5% */
      }
    }
  }
}

.siteVisitsElement {
  .siteVisitLabel {
    label {
      color: var(--2, #aa1a5f);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 144%;
      /* 25.92px */
    }

    ul {
      li {
        color: var(--10, #737373);

        /* Inputs/Label */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 137.5% */
      }
    }
  }
}

.trackingElement {
  .trackingLabel {
    label {
      color: var(--2, #aa1a5f);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 144%;
      /* 25.92px */
    }
  }
}

.ant-modal-footer {
  justify-content: center;
  align-items: center;
  gap: 15px;
  display: flex;

  .cancelButton {
    display: flex;
    height: 44px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--2, #aa1a5f);
    color: var(--2, #aa1a5f);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
  }

  .saveButton {
    border-radius: 8px;
    background: var(--2, #aa1a5f);

    /* Elevation/Buttons */
    box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
    display: flex;
    height: 44px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--character-primary-inverse, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
  }
}

.deleteText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: var(--Neutrals-Black, #3a3737);
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    /* 20.16px */
    display: flex;
    flex-direction: column;
  }

  span {
    color: var(--Neutrals-Black, #3a3737);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 144%;
  }
}

.ant-upload-list {
  display: none !important;
}

.postponeElement {
  .ant-switch-checked {
    background-color: var(--2, #aa1a5f) !important;
  }

}



.uploadsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-bottom: 10px;
  margin-top: 10px;

  .uploadContent {
    width: 100%;
    display: flex;
    padding: 12px 16px;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Primary-Main-blue-200, #DFE6FF);
    background: var(--14, #F6F6F6);

    .uploadIcon {
      display: flex;
      width: 44px;
      height: 44px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 80px;
      background: var(--6, #FADCEA);
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      flex: 1 0 0;
      height: 44px;

      h1 {
        align-self: stretch;
        color: var(--Neutrals-Black, #3A3737);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 128.571% */
      }

      p {
        color: var(--Neutrals-Gray-400, #777);
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 128.571% */
      }
    }


    img {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 1.201px 1.2px 1.199px 1.2px;
      justify-content: center;
      align-items: center;
      color: #555555;
      cursor: pointer;
    }

  }

}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.contactInfoWrapper {
  display: flex;
  padding: 24px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--6, #FADCEA);
  background: var(--7, #FFF5FA);
}
.filesWrapper{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .fileDetails {
    display: flex;
    padding: 12px 16px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Primary-Main-blue-200, #DFE6FF);
    background: var(--14, #F6F6F6);
  
    .fileIcon {
      display: flex;
      width: 44px;
      height: 44px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 80px;
      background: var(--6, #FADCEA);
  
      .fileInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        flex: 1 0 0;
  
        span {
          color: var(--Neutrals-Black, #3A3737);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 128.571% */
          align-self: stretch;
        }
  
        p {
          color: var(--Neutrals-Gray-400, #777);
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 128.571% */
        }
      }
    }
  }
}
