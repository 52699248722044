.FieldLogisticsBoard {


    .headerContainer {
        margin: 40px 40px 40px 40px;

        .headerSubContainer {
            width: 100%;
            margin-top: 60px;
            display: flex;
            padding: 32px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;
            border-radius: 8px;
            border: 1.5px solid var(--6, #FADCEA);
        }



        //FITLER STYLES
        .filterCards {
            width: 100%;
            display: flex;
            padding: 20px 16px;
            gap: 24px !important;
            flex-wrap: wrap;
            border-radius: 8px;
            border: 1.5px solid var(--13, #E6E6E6);
            background: rgba(246, 246, 246, 0.40);
        }


        .filterCardInput {
            display: flex;
            gap: 8px;
            flex-direction: column;
            align-items: flex-start;

            .ant-select {

                width: 315px !important;
            }

            label {
                color: var(--Neutrals-Black, #3A3737);

                /* Inputs/Label filters */
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                /* 157.143% */
            }
        }

        .recentVisitCardsWrapper {
            width: 100%;
            display: flex;
            flex-direction: row !important;
            align-items: stretch; // Ensure all items stretch to the same height
            gap: 32px;
            padding-bottom: 15px;
            overflow-x: auto;
        
            .visitCard {
                display: flex;
                flex-direction: column;
                justify-content: space-between; // Distribute space evenly
                min-width: 241.25px;
                padding: 12px;
                border-radius: 8px;
                border: 1px solid #E6E6E6;
                background: var(--15, #FFF);
                height: 350px; // Make sure cards take up full height of their container
        
                h1 {
                    color: var(--8, #000);
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: normal;
                }
        
                p {
                    color: var(--9, #404040);
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: normal;
                }
        
                span {
                    color: var(--9, #404040);
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                }
        
                .buttonWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    background: var(--15, #FFF);
        
                    button {
                        border-radius: 8px;
                        color: var(--4, #E164A0);
                        text-align: center;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 500;
                        border: 1px solid var(--13, #e6e6e600);
                        padding: 8px;
                        background: var(--15, #FFF);
        
                        img {
                            width: 16px !important;
                            height: 16px !important;
                        }
                    }
                }
            }
        }
        

    }

    .logisticsController {
        display: flex;
        width: 100%;
        padding: 32px 35px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        background: var(--2, #AA1A5F);

        h1 {
            color: var(--15, #FFF);
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 34px;
            /* 188.889% */
        }

        .ant-select {
            width: 460px !important;
        }
    }



}