.ant-form{
    h3{
        color: var(--2, #AA1A5F);
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 144%; /* 25.92px */
    }
}

.clearFieldsBtn{
    display: flex;
height: 44px;
padding: 4px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--6, #FADCEA);
background: var(--7, #FFF5FA);

box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
color: var(--4, #E164A0);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 157.143% */
}

.siteContactsWrapper{
    display: flex;
width: 100%;
padding: 20px;
align-items: flex-start;
align-content: flex-start;
gap: 24px 32px;
flex-wrap: wrap;
border-radius: 8px;
border: 1px solid var(--6, #FADCEA);
background: var(--7, #FFF5FA);

.siteContactForm{
    width: 100%;

    .hidden {
        height: 0;
    }

    .delete-button {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FADCEA;
        border: 1px dashed red;
        border-radius: 8px;
        cursor: pointer;
        font-size: 20px;
        color: red;
        transition: .2s all;
        
        span {
            transition: .2s all;
        }

        &:hover {
            background-color: #fff;

            span {
                transform: scale(1.3);
            }
        }
    }
}
.addOtherContactBtn{
    display: flex;
height: 60px;
padding: 10px 21px;
justify-content: center;
align-items: center;
gap: 6px;
flex: 1 0 0;
border-radius: 7px;
border: 1px dashed var(--2, #AA1A5F);
background: var(--6, #FADCEA);
color: var(--2, #AA1A5F);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 144%; /* 20.16px */
}



}

.teamDetails{
.ant-card{
    .ant-card-head{
        background: var(--14, #F6F6F6);
        .ant-card-head-title{
            color: var(--2, #AA1A5F);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 144%; /* 23.04px */
        }

    }

  
        .ant-card-body{
     
            background: var(--14, #F6F6F6);
            padding: 13px 24px;
     
        .ant-divider-horizontal{
            margin: 0;
            padding: 10px 0;
        }
    
        .seeMoreBtn{
            display: flex;
            justify-content: center;
            align-items: center;
            button{
                color: var(--2, #AA1A5F);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    border: none;
    background: none;
    box-shadow: none;
            }
        }

    }
}
}

.tripLogisticsCard{
    padding: 20px 0;
    button{
        display: flex;
width: 229px;
height: 44px;
padding: 4px 16px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid var(--6, #FADCEA);
background: var(--7, #FFF5FA);

/* Elevation/Buttons */
box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
color: var(--4, #E164A0);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 157.143% */
    }
}

.ant-upload-wrapper{
    padding: 12px 30px;
    .ant-upload-drag{
        border: none;
        background: none;
        .ant-upload-drag-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    
}

.ant-modal-footer{
    .closeButton{
 
    
     


}
}

.ant-modal-content{
    .ant-modal-footer{
        .closeButton{
            display: flex;
            height: 44px;
            padding: 4px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid var(--2, #AA1A5F);
            color: var(--2, #AA1A5F);
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */ 
        
    }
    .submitButton{
        display: flex;
        height: 44px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--2, #AA1A5F);

        /* Elevation/Buttons */
        box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
              
}
  
    }

}
