.dataCollection {
  width: fit-content;
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: #ebf2ff;

  span {
    color: #3b81fe;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}
.postDataCollection {
  width: fit-content;
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: #dfe6ff;

  span {
    color: #325899;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}
.finalization {
  width: fit-content;
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: #def5f5;
  span {
    color: #045b46;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}
.delivered {
  width: fit-content;
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: #e7f1e6;

  span {
    color: #0f7507;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}
.ipFeedback {
  width: fit-content;
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: #f5f1f8;

  span {
    color: #9670b8;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}
.closed {
  width: fit-content;
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: var(--Neutrals-Gray-0, #e8e8e8);

  span {
    color: #555555;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}

.statusDiv {
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: #ebf2ff;
  span {
    color: #3b81fe;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}

.reportingListDiv {
  display: flex;
  padding: 60px 40px 80px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  background: var(--Neutrals-White, #fff);
}

.listDesign {
    border-radius: 8px;
border: 1px solid var(--13, #E6E6E6);
background: rgba(246, 246, 246, 0.16);
display: flex;
width: 100%;
padding: 16px 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}

.titleText{ 
    color: var(--1, #6A113C) !important;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.buttonsList, .buttonsList:hover {
    display: flex;
width: 44px;
height: 44px;
padding: 4px 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
border: 1px solid var(--Neutrals-Gray-200, #C1C1C1) !important;
}