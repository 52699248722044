/* MainLayout.css */

.sider {

  background-color: #6a113c !important; /* Change background color of Sider */
  color: white; /* Change text color of Sider */
  /* padding: 48px 24px 24px 24px; */
}

.siderText {
  color: var(--13, #e6e6e6);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.menu {
  background-color: #6a113c; /* Change background color of Menu */
}

.menu-item {
  color: white; /* Default text color of Menu.Item */
}
.ant-menu-item:hover{
  background-color: #aa1a607d !important;

}

.ant-menu-dark .ant-menu-item-selected,
.costum-menu-item-active,
.costum-menu-item-active:hover{
  background-color: #aa1a5f !important; /* Change background color of selected Menu.Item */
}



.header {
  background-color: white; /* Change background color of Header */
  color: black /* Change text color of Header */;
  padding: 0 !important;
}
.ant-layout-sider-trigger {
  background-color: #6a113c !important;
}

.ant-layout-sider.ant-layout-sider-dark.sider {
  flex: 0 0 auto !important; /* Remove fixed flex basis */
  max-width: none !important; /* Remove max-width constraint */
  min-width: auto !important; /* Remove min-width constraint */
  width: 283px !important; /* Allow the width to be determined by content */
  
}


.menuDesign{
  width: 283px;
  padding: 0 24px;
}

.ant-layout-sider-children{
  position: fixed !important;
  width: 283px;
  padding: 48px 24px 24px 24px;
  padding-bottom: 50px;
}

.ant-menu-item.ant-menu-item {
  padding-left: 15px !important; 
}

.logoutMenu{
  width: 240px !important;
  z-index: 1000;
  position: absolute !important;
  left: 15px !important;
  bottom: 3% !important;
}

@media (max-height: 600px) {
  .logoutMenu{
    left: 0 !important;
    width: 96% !important;
    z-index: 1000;
    position: relative !important;
    bottom: 10% !important;
  }
  .ant-layout-sider .ant-layout-sider-children{
    overflow-y: scroll !important;
    overflow-x: hidden !important;

  }

  
}