.adminHeader {
  display: flex;
  width: 100%;
  height: 90px;
  padding: 20px 40px;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  flex-shrink: 0;
  background: #fdfdfd;
  box-shadow: 0px 4px 4px 0px rgba(221, 221, 221, 0.25);
}

.adminHeaderName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.adminHeaderNameText {
  color: var(--Blue-purple-Dark-purple, #252938);
  text-align: right;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
}

.adminHeaderNameTextPosition {
  color: var(--Greys-Dark-gray, #707073);
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}
.adminHeaderLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
  }
.adminHeaderCircle {
    width: 40px;
    height: 40px;
    background-color: #252938; /* Background color of the circle */
    border-radius: 50%; /* Makes the div circular */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .adminHeaderCircleText {
    color: var(--Greys-White, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
    letter-spacing: 2px;
    cursor: pointer;
  }
  

  .adminHeaderLogo.ant-dropdown,
  :where(.css-dev-only-do-not-override-kghr11).ant-dropdown::before{
    right: 40px !important;
    top: 80px !important;
    width: fit-content !important;
  }

  .adminHeaderLogo.ant-dropdown a{
    text-decoration: none !important;
  }