.base {
    display: flex;
    width: 100%;
    padding: 0px 40px;
    flex-direction: column;
    align-items: flex-start;

    .container {
        display: flex;
        padding: 60px 80px 80px 80px;
        flex-direction: column;
        align-items: flex-start;
        gap: 45px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--Greys-White, #FFF);

        /* Elevation/Cards/Cards */
        box-shadow: 0px 4px 12px 0px rgba(59, 75, 104, 0.25);

        .headTitle {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            align-self: stretch;

            h1 {
                color: var(--1, #6A113C);
                font-family: Poppins;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                /* 85.714% */
            }

            button {
                display: flex;
                height: 44px;
                padding: 4px 16px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                border: 1px solid var(--15, #FFF);
                background: var(--2, #AA1A5F);
                color: var(--15, #FFF);

                /* Elevation/Buttons */
                box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);

                img {
                    display: flex;
                    width: 16px;
                    height: 16px;
                    padding: 0.57px 0.576px 0.571px 0.571px;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .projectSummaryContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 60px;
            align-self: stretch;

            .subcontainer {

                display: flex;
                padding: 24px;
                flex-direction: column;
                align-items: flex-start;
                gap: 32px;
                align-self: stretch;
                border-radius: 16px;
                border: 1.5px solid var(--5, #F5B0D2);
                background: rgba(250, 220, 234, 0.35);

                h1 {
                    color: var(--2, #AA1A5F);
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 120% */
                }

                .dataContainer {
                    display: flex;
                    padding: 80px 0px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    align-self: stretch;
                    border-radius: 0px 0px 8px 8px;
                    background: var(--Neutrals-White, #FFF);

                    div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;

                        img {
                            width: 80px;
                            height: 50.526px;
                        }

                        p {
                            color: var(--Neutrals-Gray-400, #777);
                            text-align: center;
                            font-family: Lato;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 22px;
                            /* 137.5% */
                        }
                    }
                }
            }
        }
    }

}