.workspace-wrapper {
  .wrapper-header {
    display: flex;
    height: 200px;
    padding: 40px 40px 40px 60px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
    background: #fadcea;

    h3 {
      color: var(--2, #aa1a5f);
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 144%;
      /* 46.08px */
    }
  }
}

.top-content {
  padding: 52px 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  button {
    display: flex;
    height: 44px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--1, #6a113c);
    box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
    color: var(--character-primary-inverse, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
    border-style: none;
  }

  h4 {
    color: var(--10, #737373);

    /* Basic/Title */
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 145.455% */
  }

  span {
    color: var(--Neutrals-Gray, #555);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
  }
}

.workspace-box {
  // width: calc(33.333% - 20px);
  display: flex;
  width: 371.7px;
  height: 244px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--2, #aa1a5f);
  background-image: url();
  cursor: pointer;

  img {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    min-width: 100%;
    object-fit: cover;
    height: -webkit-fill-available;
  }

  h2 {
    font-size: 20px;
    color: white;
    position: absolute;
    bottom: 0;
    padding-bottom: 40px;
  }

  .ant-card-body {
    width: 100%;

    .workspaceMenu {
      button {
        .anticon {
          color: #fff;
          font-size: 30px;
        }
      }
    }
  }
}

.workspaceModalMenu {
  .ant-dropdown-menu {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    width: max-content;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    gap: 10px;
  }
}

.workspace-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px 0;
}

.title-assessment-modal {
  color: var(--2, #aa1a5f);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 30px;
  text-align: center;
  padding: 10px 0;
}

.label-name-assessment-modal {
  label {
    padding: 10px 0;
  }
}

.ant-modal-close-x {
  display: none;
}

.ant-select {
  display: block;
}

.footer-buttons {
  padding-top: 40px;
  display: flex;
  gap: 20px;

  .cancel-workspace-button {
    display: flex;
    height: 44px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--2, #aa1a5f) !important;
    color: var(--2, #aa1a5f) !important;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .add-workspace-button {
    display: flex;
    height: 44px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border-color: #aa1a5f !important;
    background: var(--2, #aa1a5f) !important;
    color: var(--character-primary-inverse, #fff) !important;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1) !important;
  }
}

@media (max-width: 768px) {
  .workspace-box {
    width: calc(50% - 20px);
    /* Two boxes per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .workspace-box {
    width: calc(100% - 20px);
    /* One box per row on very small screens */
  }
}


.label-name-assessment-modal {
  label {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
    color: #3A3737;

    span {
      color: #FF4D4F;
      padding-right: 3px;
    }
  }
}

.error-message {
  color: red
}