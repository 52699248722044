.visitsMap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  height: 500px
}

.visitsMapContainer {
    display: flex;
    width: 100%;
    height: 100%;
  }
  
  .left {
    flex: 1;
    padding: 10px;
    height: 100%;
  }
  
  .right {
    flex: 2;
    padding: 10px;
  }

  .resourcesText, .resourcesText:hover {
    color: var(--Neutrals-Black, #3A3737);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 122.222% */
text-decoration-line: underline;
  }
  