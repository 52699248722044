.ant-form{
    h3{
        color: var(--2, #AA1A5F);
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 144%; /* 25.92px */
    }
}

.clearFieldsBtn{
    display: flex;
height: 44px;
padding: 4px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid #F5B0D2 solid var(--6, #FADCEA);
background: var(--7, #FFF5FA);

box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
color: var(--4, #E164A0);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 157.143% */
}

.siteContactsWrapper{
    display: flex;
width: 100%;
padding: 20px;
align-items: flex-start;
align-content: flex-start;
gap: 24px 32px;
flex-wrap: wrap;
border-radius: 8px;
border: 1px solid #F5B0D2 solid var(--6, #FADCEA);
background: var(--7, #FFF5FA);

.siteContactForm{
    width: 100%;
}
.addOtherContactBtn{
    display: flex;
height: 60px;
padding: 10px 21px;
justify-content: center;
align-items: center;
gap: 6px;
flex: 1 0 0;
border-radius: 7px;
border: 1px solid #F5B0D2 dashed var(--2, #AA1A5F);
background: var(--6, #FADCEA);
color: var(--2, #AA1A5F);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 144%; /* 20.16px */
}



}

.teamDetails{
.ant-card{
    .ant-card-head{
        background: var(--14, #F6F6F6);
        .ant-card-head-title{
            color: var(--2, #AA1A5F);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 144%; /* 23.04px */
        }

    }

  
        .ant-card-body{
     
            background: var(--14, #F6F6F6);
            padding: 13px 24px;
     
        .ant-divider-horizontal{
            margin: 0;
            padding: 10px 0;
        }
    
        .seeMoreBtn{
            display: flex;
            justify-content: center;
            align-items: center;
            button{
                color: var(--2, #AA1A5F);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    border: none;
    background: none;
    box-shadow: none;
            }
        }

    }
}
}

.tripLogisticsCard{
    width: 100%;

    .tripLogicWrapper {
      width: 100%;
      padding: 16px 32px;
      background-color: #FFF6FA;
      border: 1px solid #F5B0D2;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #AA1A5F;
        margin: 10px 0;
      }

      button{
          display: flex;
          width: auto;
          min-width: auto;
          height: 44px;
          padding: 4px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #F5B0D2 solid var(--6, #AA1A5F);
          background: var(--7, #AA1A5F);
  
          /* Elevation/Buttons */
          box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
          color: var(--4, #fff);
          text-align: center;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
      }
    }
}

.ant-upload-wrapper{
    padding: 12px 30px;
    .ant-upload-drag{
        border: none;
        background: none;
        .ant-upload-drag-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    
}

.ant-modal-footer{
    .closeButton{
 
    
     


}
}

.ant-modal-content{
    .ant-modal-footer{
        .closeButton{
            display: flex;
            height: 44px;
            padding: 4px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid #F5B0D2 solid var(--2, #AA1A5F);
            color: var(--2, #AA1A5F);
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */ 
        
    }
    .submitButton{
        display: flex;
        height: 44px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--2, #AA1A5F);

        /* Elevation/Buttons */
        box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
              
}
  
    }

}

.teamAdd {
    background-color: #FFF5FA;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #F5B0D2;
    margin-bottom: 25px;
}

.indentifierTracking {
    .ant-form-item {
        .ant-form-item-control-input {
            .ant-form-item-control-input-content {
                input:disabled {
                    background: white;
                    color: black;
                }
            }
        }
    }
}