.accountSettings {
  display: flex;
  padding: 60px 80px 80px 80px;
  flex-direction: column;

  gap: 45px;
  border-radius: 8px;
  background: var(--Greys-White, #fff);
  width: 80%;
  box-shadow: 0px 1px 6px 0px rgba(165, 177, 217, 0.77);
  margin: auto;
  margin-top: 40px;
  margin: auto;

  .title {
    color: var(--1, #6a113c);
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
  }

  h4 {
    color: var(--2, #aa1a5f);
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
  }


 
}

.profileInformation {
  margin-top: 40px;
  margin-bottom: 40px;
  .userLogo {
    height: 150px;
    background: var(--5, #F5B0D2);
    position: relative;
    margin: 0 0 100px 0;
    border-radius: 8px 8px 0px 0px;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 70px;
      transform: translateY(50%);
      height: 120px;
      width: 120px;
      background: var(--2, #AA1A5F);
      border-radius: 50%;
      border: 4px solid white;

      span {
        font-weight: bold;
        color: white;
        font-size: 46px;
      }
    }
  }

  .profileContent {
    margin: 0 20px;

    @media only screen and (min-width: 768px) {
      margin: 0 70px;
    }

    @media only screen and (min-width: 1200px) {
      margin: 0 150px 0 70px;
    }
  }

  .personalInformation {
    .required::before {
      content: "* ";
      color: red;
    }

    .label-text {
      font-size: 13px;
      font-weight: 500;
    }

    .react-tel-input {
      .form-control {
        width: 100%;
      }
    }
  }
}

.loginInformation {
  padding: 40px;
}

.label-text {
   color: var(--character-title-85, rgba(0, 0, 0, 0.85));

/* Inputs/Label */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 137.5% */
}

.headingDeactivate{
   color: var(--2, #AA1A5F);
text-align: center;

/* Basic/Title */
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 144%; /* 31.68px */
}