.wrapper {
    background: #fff5fa;
    margin: 0;
    padding: 0;
  
    .left-column {
      .leftImage {
        height: 100vh;
      }
    }
    .right-column {
      display: flex;
      justify-content: center;
      align-items: center;
      .form-wrapper {
        display: flex;
        width: 553px;
        padding: 80px 60px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 4px 12px 0px rgba(106, 17, 60, 0.25);
      }
      p {
        color: var(--greys-50-b, #808080);
        font-family: "Source Sans 3";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 135%; /* 29.7px */
      }
      h2 {
        color: #d42177;
  
        /* H4 - 36 */
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 45px */
        letter-spacing: 0.36px;
      }
      label {
        color: var(--Neutrals-Black, #3a3737);
  
        /* Inputs/Label */
        font-family: Poppins;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 137.5% */
      }
  
      button {
        display: flex;
        width: 100%;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--Greys-White, #fff);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        border-radius: 8px;
        background: #d42177;
  
        border-style: none;
        box-shadow: 0px 3px 5px 0px rgba(128, 128, 128, 0.35);
      }
  
      .form-check {
        display: flex;
        gap: 5px;
      }
    }
    .row {
      --bs-gutter-x: 0;
    }
  }
  
  .forgotPassword {
    color: var(--3, #D42177) !important;
  text-align: center;
  
  /* Inputs/Message */
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important; /* 183.333% */
  cursor: pointer;
  }
  